import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>お探しのページが見つかりませんでした。</p>
    <Link to="/">トップに戻る</Link>
  </Layout>
)

export default NotFoundPage
